import React from 'react';
import { graphql, Link } from 'gatsby';
import { Breadcrumb } from 'gatsby-plugin-breadcrumb';
import styled from 'styled-components';
import Hero from '../components/Hero';
import SEO from '../components/SEO';
import Image from '../components/Image';
import { Section } from '../styles/common/Common';
import { BreadcrumbWrapper } from '../styles/common/Element';
import mq from '../styles/media-queries';
import { Container, Row } from '../styles/common/Layout';

const ServiceItemsWrapper = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  margin: 20px 20px 20px;
  width: 100%;

  ${mq.md} {
    width: calc(100% + var(--gap_desktop));
    margin: 20px 0;
  }

  > * {
    width: 100%;
    margin-bottom: 20px;

    ${mq.md} {
      width: 325px;
      margin: var(--gap_desktop) 0 0 var(--gap_desktop);
    }
  }
`;
const ServiceItem = styled.div`
  display: flex;

  a {
    width: 100%;
    height: 220px;
    position: relative;
    overflow: hidden;

    figure {
      transition: 1s filter linear, 1s -webkit-filter linear;
      ${mq.md} {
        filter: grayscale(75%);
      }
    }

    ${mq.md} {
      &:hover {
        span {
          transition: 1s;
          bottom: 0px;
        }

        figure {
          filter: grayscale(0%);
        }
      }
    }
  }
`;

const OverlayWrapper = styled.span`
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 50px;
  background: var(--mainColor);
  color: var(--white);
  transition: 1s;
  padding: 5px;
  padding: 5px;
  display: flex;
  align-content: center;
  justify-content: center;

  ${mq.md} {
    bottom: -50px;
  }

  h1 {
    font-size: 1.7rem;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
  }
`;

export default function PhysiotherapiePage({ pageContext, data }) {
  const {
    breadcrumb: { crumbs },
  } = pageContext;
  const services = data.services.nodes;

  return (
    <>
      <SEO title="Physiotherapie" />
      <Hero slug="startseite" small>
        <h1 className="headline">Physiotherapie</h1>
        <h2 className="sub-headline">
          Eine Übersicht unserer Leistungen &amp; Therapieangebote
        </h2>
      </Hero>
      <Container as="section">
        <Row>
          <BreadcrumbWrapper>
            <Breadcrumb
              crumbs={crumbs}
              crumbSeparator=" / "
              crumbLabel="Physiotherapie"
            />
          </BreadcrumbWrapper>
        </Row>
        <Row>
          {services && (
            <>
              <ServiceItemsWrapper>
                {services.map((service) => (
                  <ServiceItem key={service._id}>
                    <Link to={service.slug.current}>
                      <Image
                        image={service.contentImage.asset.gatsbyImageData}
                        alt={service.pageName}
                      />
                      <OverlayWrapper>
                        <h1>{service.pageName}</h1>
                      </OverlayWrapper>
                    </Link>
                  </ServiceItem>
                ))}
              </ServiceItemsWrapper>
            </>
          )}
        </Row>
      </Container>
    </>
  );
}

export const query = graphql`
  query PhysiotherapiePageQuery {
    services: allSanityPageContent(
      filter: {
        slug: {
          current: { nin: ["startseite", "impressum", "praxis", "corona"] }
        }
      }
    ) {
      nodes {
        id
        _id
        pageName
        slug {
          current
        }
        contentImage {
          asset {
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
          }
        }
      }
    }
  }
`;
